/* STYLES */

//"Assistant", sans-serif

body {
  --site-body-text: AvenirLight;
}

@font-face {
  font-family: UKReg;
  src: url("./assets/test.ttf") format("truetype");
}

@font-face {
  font-family: comparenamefont;
  src: url("./assets/harabara_mais_demo.otf") format("truetype");
}

@font-face {
  font-family: UKRegExtended;
  src: url("./assets/important.ttf") format("truetype");
}

/* AVENIR */
@font-face {
  font-family: AvenirBlack;
  src: url("./assets/fonts/Avenir\ Black/Avenir Black.ttf") format("truetype");
}

@font-face {
  font-family: AvenirBook;
  src: url("./assets/fonts/Avenir\ Book/Avenir Book.ttf") format("truetype");
}

@font-face {
  font-family: AvenirHeavy;
  src: url("./assets/fonts/Avenir\ Heavy/Avenir Heavy.ttf") format("truetype");
}

@font-face {
  font-family: AvenirLight;
  src: url("./assets/fonts/Avenir\ Light/Avenir Light.ttf") format("truetype");
}

@font-face {
  font-family: Avenir;
  src: url("./assets/fonts/Avenir\ Regular/Avenir Regular.ttf")
    format("truetype");
}

.bootstrap {
  .fw-bold:not(mat-icon, .plate-font, .inclusive-plate-font, .special-title) {
    font-family: AvenirBlack !important;
  }
  .fw-bolder:not(mat-icon, .plate-font, .inclusive-plate-font, .special-title) {
    font-family: AvenirHeavy !important;
  }
  .fw-normal:not(mat-icon, .plate-font, .inclusive-plate-font, .special-title) {
    font-family: Avenir !important;
  }
  .fw-light:not(mat-icon, .plate-font, .inclusive-plate-font, .special-title) {
    font-family: AvenirLight !important;
  }
}

.bootstrap mat-icon.outline {
  font-family: "Material Icons Outlined" !important;
}

*:not(
    mat-icon,
    .mat-icon,
    .plate-font,
    .inclusive-plate-font,
    .special-title,
    .material-icons
  ) {
  font-family: var(--site-body-text) !important;
  letter-spacing: 0.4px !important;
  font-weight: 500;
  color: #2f2f2f;

  b.strong {
    font-weight: 600;
  }
}
/* END AVENIR */

/* width */
::-webkit-scrollbar {
  width: 5px;
  height: 5px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #f1f1f1;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #888;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #555;
}

* {
  box-sizing: border-box;
}

.pointer {
  cursor: pointer;
}

.hover-interact {
  opacity: 1;

  &:hover {
    opacity: 0.75;
  }
}

html {
  scroll-behavior: smooth;
}

html,
body {
  height: 100%;
  user-select: none;
  caret-color: black !important;
}

body.locked {
  overflow: hidden;
  width: 100%;
  height: 100svh;
  margin: 0;
}

body {
  margin: 0;
}

.site-header-container {
  .site-header {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    width: 100%;
    z-index: 1003;
  }

  &.fixed {
    .spacer {
      height: 54px;

      &.full {
        height: 95px;
      }
    }
  }
}

img,
svg {
  vertical-align: super !important;
}

.ultra-light {
  font-weight: 200;
  color: rgb(118, 118, 118);
  display: block;

  &.centered {
    text-align: center;
    margin: auto;
  }
}

input {
  letter-spacing: 1.15px;
}

.mat-typography {
  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    margin-bottom: 0;
  }
}

.hr-light {
  width: 100%;
  margin: 15px auto;
  height: 1px;
  background: #c2c2c2;

  &.small-spacing {
    margin: 5px auto;
  }
}

.hr-table-light {
  border-bottom: 1px solid #c2c2c2;
}

.platex-background {
  background: #29576a;
  color: white;
}

.platex-list {
  padding-left: 1rem !important;

  li.no-marker {
    list-style-type: none;
  }
  li::marker {
    color: #3791a8;
    font-size: 20px;
    height: 20px;
    width: 20px;
    line-height: 20px;
  }
}

.registration-background {
  background: #ffc72c;
  color: black;
}

.pad-header {
  padding-top: 40px;
}

@media only screen and (max-width: 650px) {
  .pad-header {
    padding-top: 0;
  }
}

.text-section {
  width: 100%;
  margin: auto;

  h1 {
    margin: 0;
  }

  .page-break {
    height: 15px;
  }

  p,
  li span,
  p span {
    font-family: var(--site-body-text) !important;
    //font-family: "Assistant", sans-serif !important;
    font-weight: 300;
  }

  h1,
  h2,
  h3,
  h4 {
    font-weight: 500;
  }

  .text-title {
    &.heading {
      border-bottom: 1px solid #c2c2c2;
    }

    // margin-top: 20px;
    margin-bottom: 10px;

    h2 {
      margin-bottom: 5px;
    }
  }

  .text-paragraph {
    .indent {
      // margin-left: 20px;
    }

    .faq-item {
      margin-top: 20px;
      display: flex;
      align-items: center;
      justify-content: flex-start;

      .mat-icon {
        color: #327b8d;
        margin-bottom: 20px;
        margin-right: 10px;
        font-size: 30px;
        width: 30px;
        height: 30px;
        line-height: 30px;
      }

      h4 {
        font-size: 20px;
        margin-bottom: 20px;
      }
    }
  }
}

.text-center {
  text-align: center;
}

.text-collapse {
  &.toggler {
    cursor: pointer;
  }

  &.content,
  &.content:not(.closed) {
    transition-duration: 0.5s;
    max-height: initial;
    overflow: auto;
  }

  &.content.closed {
    transition-duration: 0.2s;
    max-height: 0;
    overflow: hidden;
  }
}

@media only screen and (max-width: 700px) {
  .text-section {
    max-width: 100%;
    margin: auto;
  }
}

span:not(.plate-font),
p:not(.plate-font),
li:not(.plate-font),
.bootstrap .fs-6 {
  font-size: 13px !important;
  line-height: 25px;
  letter-spacing: 0.75px;
}

ul {
  margin: 10px 0;

  li {
    margin: 10px 0;
  }

  span {
    color: #000000de;
  }
}

.plate-font {
  font-family: "UKReg" !important;
}

.inclusive-plate-font,
.plate-font.inclusive-plate-font {
  font-family: "UKRegExtended" !important;
}

.compare-site-name-text {
  font-family: "comparenamefont" !important;
}

.special-title {
  font-family: "Arimo", sans-serif !important;
}

select:not(.mat-select) {
  margin: 5px auto;
  background-color: white;
  border: 1px solid #e5e5e5;
  color: black;
  width: 100%;
  padding: 5px 10px;
  background-image: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  font-size: 18px;
  border-radius: 4px;
  outline: none;
  background-image: url("data:image/svg+xml;utf8,<svg fill='black' height='24' viewBox='0 0 24 24' width='24' xmlns='http://www.w3.org/2000/svg'><path d='M7 10l5 5 5-5z'/><path d='M0 0h24v24H0z' fill='none'/></svg>");
  background-repeat: no-repeat;
  background-position-x: calc(100% - 10px);
  background-position-y: 5px;
  cursor: pointer;
}

mat-icon.mat-icon,
span.material-icons,
.bootstrap mat-icon.mat-icon,
.bootstrap span.material-icons {
  vertical-align: middle;

  &.i-center {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  &.x-large {
    font-size: 50px !important;
    width: 50px !important;
    height: 50px !important;
  }

  &.m-large {
    font-size: 40px !important;
    width: 40px !important;
    height: 40px !important;
  }

  &.large {
    font-size: 30px !important;
    width: 30px !important;
    height: 30px !important;
  }

  &.mid {
    font-size: 25px !important;
    width: 25px !important;
    height: 25px !important;
  }

  &.small {
    font-size: 20px !important;
    width: 20px !important;
    height: 20px !important;
  }

  &.x-small {
    font-size: 15px !important;
    width: 15px !important;
    height: 15px !important;
  }

  &.micro {
    font-size: 10px !important;
    width: 10px !important;
    height: 10px !important;
  }
}

@media only screen and (max-width: 768px) {
  mat-icon.mat-icon {
    &.x-large {
      font-size: 40px;
      width: 40px;
      height: 40px;
    }

    &.large {
      font-size: 25px;
      width: 25px;
      height: 25px;
    }

    &.small {
      font-size: 20px;
      width: 20px;
      height: 20px;
    }

    // mobile only
    &.mb-x-large {
      font-size: 40px;
      width: 40px;
      height: 40px;
    }

    &.mb-large {
      font-size: 25px;
      width: 25px;
      height: 25px;
    }

    &.mb-small {
      font-size: 20px;
      width: 20px;
      height: 20px;
    }
  }
}

.sell-page .swiper .swiper-wrapper .swiper-slide {
  height: auto;
  display: flex;
  margin: 0 auto;
}

.pla-mini-fab {
  transform: scale(0.75) !important;
}

.row-action {
  button {
    max-height: 34px;

    span:not(.inline) {
      line-height: 34px;
      display: block;
    }
  }
}

.plate span.mat-badge-content {
  font-size: 12px;
}

h1 {
  color: black;
}

.disabled {
  opacity: 0.2;
}

.top-section {
  padding-top: 60px;
}

.section h1 {
  font-size: 32px;
  font-weight: bold;
}

.page {
  background-color: #fff;
  min-height: 80vh;
  box-sizing: border-box;
}

._card {
  overflow: hidden;

  &.padding,
  .section.padding {
    padding: 20px 10px;
  }

  &.spacing,
  .section.spacing {
    margin: 10px;

    &.no-left {
      margin-left: 0;
    }

    &.no-right {
      margin-right: 0;
    }

    &.no-top {
      border-top-left-radius: 0;
      border-top-right-radius: 0;
      margin-top: 0;
    }

    &.no-bottom {
      margin-bottom: 0;
    }
  }
}

.card-spacing {
  margin: 10px;

  &.no-left {
    margin-left: 0;
  }

  &.no-right {
    margin-right: 0;
  }

  &.no-top {
    border-top-left-radius: 0;
    border-top-right-radius: 0;
    margin-top: 0;
  }

  &.no-bottom {
    margin-bottom: 0;
  }
}

.pla_card,
.no-card {
  &:not(.no-card) {
    background: #f5f5f5;
    // background: white;
  }

  border-radius: 3px;
  overflow: visible;

  &.overlap {
    overflow: visible !important;
  }

  &.overflow {
    overflow: visible !important;
  }

  &.fit-content {
    width: fit-content;
  }

  &.ds {
    box-shadow: 4px 4px 4px rgba(0, 0, 0, 0.3);
  }

  &:not(.no-ds) {
    // box-shadow: 2px 2px 4px 4px rgba(0, 0, 0, 0.04);
  }

  &.even-padding {
    padding: 20px;
  }

  &.padding,
  &.section.padding {
    padding: 20px 10px;

    &.no-p-left {
      padding-left: 0;
    }

    &.no-p-right {
      padding-right: 0;
    }

    &.no-p-top {
      padding-top: 0;
    }

    &.no-p-bottom {
      padding-bottom: 0;
    }
  }

  &.spacing,
  &.section.spacing {
    margin: 10px;

    &.no-left {
      margin-left: 0;
    }

    &.no-right {
      margin-right: 0;
    }

    &.no-top {
      border-top-left-radius: 0;
      border-top-right-radius: 0;
      margin-top: 0;
    }

    &.no-bottom {
      margin-bottom: 0;
    }
  }
}

@media only screen and (max-width: 750px) {
  .card.mobile {
    border-radius: 0;
  }

  ._card,
  .pla_card {
    &.mobile.padding {
      padding: 20px 5px;

      &.no-p-left {
        padding-left: 0;
      }

      &.no-p-right {
        padding-right: 0;
      }

      &.no-p-top {
        padding-top: 0;
      }

      &.no-p-bottom {
        padding-bottom: 0;
      }
    }

    &.mobile.spacing,
    .section.mobile.spacing {
      margin: 10px auto;

      &.no-left {
        margin-left: 0;
      }

      &.no-right {
        margin-right: 0;
      }

      &.no-top {
        border-top-left-radius: 0;
        border-top-right-radius: 0;
        margin-top: 0;
      }

      &.no-bottom {
        margin-bottom: 0;
      }
    }
  }
}

.tabbed-container-parent {
  position: relative;
}

.centered-container {
  display: flex;
  height: 100%;
  justify-content: center;
  align-items: center;
}

.flex-container {
  display: flex;
  justify-content: space-between;
}

.flex-space {
  flex: 1 1 auto;
}

.info-tooltip {
  font-size: 13px;
  text-align: center;
}

.banner {
  width: 100%;
  height: 40vh;
  position: relative;
  box-shadow: 0px 8px 8px rgba(0, 0, 0, 0.3);
}

.banner-image-container {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  width: 100%;
  height: 100%;
}

.black-and-white::before {
  filter: grayscale(100%) brightness(0.4);
}

.grey-out::before {
  filter: grayscale(0%) brightness(0.7);
}

.banner-image::before {
  content: "";
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background-size: cover;
  /* background-size: 100% 150%; */
  background-repeat: no-repeat;
  background-position: center center;
}

.banner-content {
  position: absolute;
  width: 100%;
  top: 50%;
  right: 0;
  bottom: 0;
  left: 0;
  transform: translateY(-50%);
}

.banner-heading {
  /* margin: 45px auto; */
  margin: auto;
  padding-top: 20px;
}

.banner-heading h1 {
  text-align: center;
  color: white;
  font-size: 42px;
  font-weight: bold;
}

.banner-action {
  margin: 25px auto auto;
  text-align: center;
}

.section {
  padding: 20px 10px;
  color: black;
}

.accent {
  background-color: #e2e2e2;
}

.white-background {
  background-color: white;
}

.section.large {
  padding: 50px 10px;
}

.section.space {
  margin: 50px auto;
}

.section.fill {
  padding: 0;
}

.vertical-section {
  padding: 20px 0;
}

.icon-button {
  vertical-align: middle;
}

.icon-button .mat-icon {
  vertical-align: text-top !important;
}

.page-width {
  width: 80%;
  margin: auto;
  max-width: 800px;
}

.page-container {
  max-width: 800px;
}

.landing-section {
  padding: 70px 0;
  position: relative;
}

.small-landing-section {
  padding: 30px 0;
  position: relative;
}

.landing-center {
  position: absolute;
  width: 100%;
  top: 50%;
  left: 0;
  right: 0;
  bottom: 0;
  transform: translateY(-50%);
}

.plate-background {
  &:not(.white):not(.no-colour):not(.flat) {
    background-color: #ffc72c !important;

    input {
      &::placeholder {
        color: #c4a418;
        font-size: 25px;
      }
    }
  }

  .custom-value,
  .custom-placeholder,
  input {
    &::selection {
      background: white;
    }
  }

  &.flat {
    box-shadow: none !important;
    background-color: #fedd31 !important;
    &:not(.shaded) {
      background-image: linear-gradient(
        to bottom right,
        rgba(255, 255, 255, 0.03125) 0,
        rgba(255, 255, 255, 0.03125) 50%,
        rgba(255, 255, 255, 0.031) 51%,
        rgba(255, 255, 255, 0) 100%
      ) !important;
    }

    &.shaded {
      background-image: linear-gradient(
        to bottom,
        rgba(255, 255, 255, 0.55) 0,
        rgba(255, 255, 255, 0) 75%,
        rgba(255, 255, 255, 0) 100%
      ) !important;
    }
  }

  &.white {
    background-color: white;

    input {
      &::placeholder {
        color: #b9b9b9;
        font-size: 25px;
      }
    }
  }

  &.rounded {
    border-radius: 6px;
  }

  background-image: linear-gradient(
    to bottom right,
    rgba(255, 255, 255, 0.03125) 0,
    rgba(255, 255, 255, 0.03125) 50%,
    rgba(0, 0, 0, 0.03125) 51%,
    rgba(0, 0, 0, 0) 100%
  ) !important;
  box-shadow: inset 1px 1px 1px 1px rgb(255 255 255 / 25%),
    inset 0 1px rgb(255 255 255 / 50%),
    inset 0 -0.25em 1em -0.4em rgb(0 0 0 / 25%),
    inset 0 0.5em 0.5em -0.4em rgb(255 255 255 / 50%),
    0 0 0 1px rgb(0 0 0 / 10%), 0 0.05em 0.192em rgb(0 0 0 / 50%) !important;

  input {
    text-align: center;
    color: black;
    outline: black;
    border: none;
    background: none;
    outline: none !important;
    width: 100%;
    font-size: 25px;
    padding: 11px;
    box-sizing: border-box;
    height: 100%;
    min-width: 8ch;
  }
}

.plate-surround {
  min-width: 350px;
  border-radius: 6px;
  background-color: #ffc72c;
  background-image: linear-gradient(
    to bottom right,
    rgba(255, 255, 255, 0.03125) 0,
    rgba(255, 255, 255, 0.03125) 50%,
    rgba(0, 0, 0, 0.03125) 51%,
    rgba(0, 0, 0, 0) 100%
  );
  box-shadow: inset 1px 1px 1px 1px rgb(255 255 255 / 25%),
    inset 0 1px rgb(255 255 255 / 50%),
    inset 0 -0.25em 1em -0.4em rgb(0 0 0 / 25%),
    inset 0 0.5em 0.5em -0.4em rgb(255 255 255 / 50%),
    0 0 0 1px rgb(0 0 0 / 10%), 0 0.05em 0.192em rgb(0 0 0 / 50%);
  user-select: none;
  box-sizing: border-box;
  padding: 0px 10px;
  overflow: hidden;
  border-collapse: collapse;

  &.smaller {
    min-width: 275px;
  }
}

@media only screen and (max-width: 750px) {
  .plate-surround.smaller {
    min-width: 215px;
  }
}

@media only screen and (max-width: 350px) {
  .plate-surround {
    min-width: 0;
  }
}

// div.plate {
//   width: 200px;
//   max-width: 100%;
//   border-radius: 6px;
//   background: #ffc72c;
//   margin: 10px auto;
//   text-align: center;
//   cursor: pointer;
//   user-select: none;
//   box-sizing: border-box;
// }

div.plate-style-important {
  font-family: "UKReg" !important;
  border-collapse: collapse;
  color: black;
  display: block;
  padding: 5px 10px;
  font-size: 40px;
  line-height: 100%;
  margin: 0;
  overflow: hidden;
  text-overflow: clip;
  white-space: nowrap;
  text-shadow: -1px -1px rgb(255 255 255 / 50%);
  background-color: #f9cd27;
  background-image: linear-gradient(
    to bottom right,
    rgba(255, 255, 255, 0.03125) 0,
    rgba(255, 255, 255, 0.03125) 50%,
    rgba(0, 0, 0, 0.03125) 51%,
    rgba(0, 0, 0, 0) 100%
  );
  box-shadow: inset 1px 1px 1px 1px rgb(255 255 255 / 25%),
    inset 0 1px rgb(255 255 255 / 50%),
    inset 0 -0.25em 1em -0.4em rgb(0 0 0 / 25%),
    inset 0 0.5em 0.5em -0.4em rgb(255 255 255 / 50%),
    0 0 0 1px rgb(0 0 0 / 10%), 0 0.05em 0.192em rgb(0 0 0 / 50%);
}

div.plate-style-important.white {
  font-family: "UKReg" !important;
  border-collapse: collapse;
  color: black;
  display: block;
  padding: 5px 10px;
  font-size: 40px;
  line-height: 100%;
  margin: 0;
  overflow: hidden;
  text-overflow: clip;
  white-space: nowrap;
  text-shadow: -1px -1px rgb(255 255 255 / 50%);
  background-color: #fff;
  background-image: linear-gradient(
    to bottom right,
    rgba(255, 255, 255, 0.03125) 0,
    rgba(255, 255, 255, 0.03125) 50%,
    rgba(0, 0, 0, 0.03125) 51%,
    rgba(0, 0, 0, 0) 100%
  );
  box-shadow: inset 1px 1px 1px 1px rgb(255 255 255 / 25%),
    inset 0 1px rgb(255 255 255 / 50%),
    inset 0 -0.25em 1em -0.4em rgb(0 0 0 / 25%),
    inset 0 0.5em 0.5em -0.4em rgb(255 255 255 / 50%),
    0 0 0 1px rgb(0 0 0 / 10%), 0 0.05em 0.192em rgb(0 0 0 / 50%);
}

div.faded-plate.plate .plate-text,
div.faded-plate.plate p {
  opacity: 0.3;
}

div.plate input {
  // background: #ffc72c;
  border: none;
  border-radius: 6px;
  width: 100%;
  text-align: center;
  font-family: "UKReg" !important;
  border-collapse: collapse;
  color: black;
  display: block;
  padding: 10px 15px;
  font-size: 40px;
  line-height: 100%;
  margin: 0;
  overflow: hidden;
  text-overflow: clip;
  white-space: nowrap;
  box-sizing: border-box;
  outline: none;
}

div.plate.plate-input {
  width: auto;
  background: red;
  max-width: 300px;
  margin: auto;
}

div.plate-input input {
  padding: 15px 30px;
}

div.plate input::placeholder {
  color: rgba(0, 0, 0, 0.3);
}

.section p {
  font-size: 19px;
  line-height: 1.6em;
}

.section p.small {
  font-size: 16px;
}

.mobile-only {
  display: none !important;
}

.desktop-only {
  display: block !important;
}

.desktop-flex-only {
  display: flex !important;
}

.accent-text {
  color: #327b8d;
}

.large-desktop-only {
  display: none !important;
}

.cdk-overlay-container .no-padding .mat-dialog-container {
  padding: 0 !important;
  overflow: hidden !important;
}

.seperator {
  width: 100%;
  margin: 15px auto;
  display: flex;
  justify-content: space-between;
  align-items: center;

  .line {
    height: 1px;
    background-color: #c2c2c2;
    width: 100%;
    flex: 1 1 auto;
  }

  span {
    color: black;
    margin: 0 10px;
  }
}

.mobile-flex {
  display: flex;
}

.buttons-container {
  display: flex;
  justify-content: space-between;
  margin: 20px auto;
}

.btn-pusher {
  flex: 1 1 auto;
}

.btn-container {
  padding: 5px;
  box-sizing: border-box;
  margin: auto;
}

.btn-container-padding {
  box-sizing: border-box;
  margin: auto 20px;
  padding: 0;
}

.btn-container-no-padding {
  box-sizing: border-box;
  margin: auto;
  padding: 0;
}

.btn-container-no-right-padding {
  box-sizing: border-box;
  // margin-left: auto;
  padding: 5px 0px 5px 5px;

  button:not(.mat-icon-button) {
    width: 100%;
  }
}

.btn-container-no-hor-padding {
  box-sizing: border-box;
  margin: auto 0 auto auto;
  padding: 5px 0;

  button:not(.mat-icon-button) {
    width: 100%;
  }
}

.btn-container-full-width {
  box-sizing: border-box;
  flex: 1;
  padding: 5px;

  button:not(.mat-icon-button) {
    width: 100%;
  }
}

.btn-container button {
  line-height: 30px;
  /* background-color: magenta; */
}

.green-button {
  color: white;
  background-color: #72cc25;
}

/**/
.check-input-container {
  .input-container {
    position: relative;

    input {
      width: 25px;
      height: 25px;
    }
  }
}

.text-input-container {
  display: flex;
  border-radius: 4px;
  border: none;
  overflow: hidden;
  width: 100%;
  max-width: 400px;
  margin: 5px 0;
  align-items: stretch;

  background-color: #fefefe;
  border: 1px solid #327b8d;

  &.error {
    border: 1px solid red;
  }

  &.text-plate-input {
    .input-container {
      input {
        text-align: center;
        font-size: 30px;
        padding: 8px;
      }
    }
  }

  .prefix {
    font-size: 25px;
    padding: 17px 5px 17px 17px;
  }

  .input-container {
    position: relative;
    flex: 1 1 auto;

    input {
      &.prefix {
        padding-left: 0;
      }

      border: none;
      background: none;
      outline: none !important;
      width: 100%;
      font-size: 22px;
      padding: 4px 8px;
      box-sizing: border-box;

      &::placeholder {
        color: #c2c2c2;
      }
    }
  }

  .search-button-container {
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: stretch;
    flex-direction: column;
    flex-grow: 1;
    color: white;
    background-color: #327b8d;
    padding: 0 20px;

    &:hover {
      background-color: #4e9cb1;
    }

    mat-icon {
      margin: auto;
      font-size: 30px;
    }
  }
}

.raw-input-container {
  input {
    text-align: center;
    color: black;
    outline: black;
    border: none;
    background: none;
    outline: none !important;
    width: 100%;
    font-size: 25px;
    padding: 11px;
    box-sizing: border-box;
    height: 100%;
    min-width: 8ch;

    &::placeholder {
      color: #b9b9b9;
      font-size: 25px;
    }
  }
}

@media only screen and (max-width: 600px) {
  .text-input-container {
    max-width: 300px;

    &:not(.text-plate-input) {
      .input-container {
        padding: 8px;

        input {
          padding: 0;
        }
      }
    }

    .search-button-container {
      padding: 0 15px;
    }
  }
}

/**/

.status-container {
  max-width: 100px;
  box-sizing: border-box;
  width: 100%;

  .status-icon {
    border-radius: 4px;
    padding: 3px 5px;
    margin: auto;
    width: 100%;
    box-sizing: border-box;
    margin-right: 0;

    display: flex;
    justify-content: center;
    align-items: center;

    &.positive {
      color: #317031;
      background-color: #e6fdc2;
    }

    &.negative {
      color: #703131;
      background-color: #fdc2c2;
    }

    &.neutral {
      color: #314770;
      background-color: #c2cdfd;
    }

    mat-icon {
      margin-right: 5px;
    }

    span {
      display: block;
      text-align: center;
      font-size: 13px;
      font-weight: 400;
      text-transform: capitalize;
    }
  }
}

.spinner-loader,
.spinner-button,
.spinner-icon-button {
  display: flex;
  justify-content: center;
  align-items: center;

  &:not(.inline) {
    flex-direction: column;
  }

  &.full {
    width: 100%;
    height: 100%;
  }

  .spinner {
    &.cyan {
      svg {
        circle {
          stroke: #327b8d;
        }
      }
    }

    &.white {
      svg {
        circle {
          stroke: #fff;
        }
      }
    }

    &.black {
      svg {
        circle {
          stroke: #000;
        }
      }
    }
  }
}

.spinner-button:not(.replacement) {
  .spinner {
    margin-left: 10px;
  }
}

.loading-overlay {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 2;

  &.backdrop,
  &.backdrop.lighter {
    background-color: rgba(255, 255, 255, 0.4);
  }

  &.backdrop.darker {
    background-color: rgba(0, 0, 0, 0.4);
  }
}

.tick-container,
.mid-container,
.cross-container {
  margin: auto;
  text-align: center;

  &.greyed {
    opacity: 0.25;
  }

  .mat-icon {
    font-size: 30px;
    width: 30px;
    height: 30px;
  }
}

.tick-container {
  color: #327b8d;
}

.mid-container {
  color: #ffc72c;
}

.cross-container {
  color: red;
}

.mat-flat-button.mat-button-disabled.mat-button-disabled {
  background-color: initial;
  opacity: 0.7;
  cursor: default;
}

.cyan-button span,
.neon-button span,
.navy-button span,
.lighter-navy-button span,
.platex-button span,
.dark-green-button span,
.turquoise-button span,
.white-text-button span {
  color: white !important;
}

.cyan-button {
  color: white !important;
  background-color: #327b8d !important;
  border-color: #327b8d !important;
  border: 1px solid #327b8d !important;
}

.neon-button {
  color: white !important;
  background-color: #1fc29b !important;
  border-color: #1fc29b !important;
  border: 1px solid #1fc29b !important;
}

.navy-button {
  color: white !important;
  background-color: #41acb1 !important;
  border-color: #41acb1 !important;
  border: 1px solid #41acb1 !important;
}

.lighter-navy-button {
  color: white !important;
  background-color: #7eafc3 !important;
  border-color: #7eafc3 !important;
  border: 1px solid #7eafc3 !important;
}

.dark-green-button {
  color: white !important;
  background-color: #1b9b8a !important;
  border-color: #1b9b8a !important;
  border: 1px solid #1b9b8a !important;
}

.platex-button {
  color: white !important;
  background-color: #29576a !important;
  border-color: #29576a !important;
  border: 1px solid #29576a !important;
}

.platex-border-button {
  color: #29576a !important;
  border-color: #29576a !important;
  border: 1px solid #29576a !important;
  background-color: transparent;

  &.greyed {
    color: #000 !important;
    border-color: #00000061 !important;
  }

  &:hover {
    background-color: #29576a;
    color: #fff !important;
  }
}

.white-border-button {
  color: #fff !important;
  border-color: #fff !important;
  border: 1px solid #fff !important;
  background-color: transparent;

  &:hover {
    background-color: #fff;
    color: #000 !important;
  }
}

.turquoise-button {
  color: white !important;
  background-color: #007850 !important;
  border-color: #007850 !important;
  border: 1px solid #007850 !important;
}

.turquoise-border-button {
  color: #007850 !important;
  border-color: #007850 !important;
  border: 1px solid #007850 !important;
}

.cyan-border-button {
  color: #327b8d !important;
  border-color: #327b8d !important;
  border: 1px solid #327b8d !important;
}

.white-tabbed-button {
  background-color: white !important;
  border-color: black !important;
  border: 1px solid black !important;
  color: black !important;
}

.large-button {
  font-size: 20px;
  width: 200px;
  padding: 10px;
}

.text-button {
  color: black;
  text-decoration: underline;
}

.white-text-button {
  color: white;
  text-decoration: underline;
}

.glossary-link {
  color: #327b8d !important;
  text-decoration: underline;

  &:hover {
    font-style: italic;
  }
}

.link {
  color: #327b8d !important;
  text-decoration: underline;
}

.white-text-button,
.text-button {
  &:hover {
    font-style: italic;
    cursor: pointer;
    opacity: 0.9;
  }
}

.button-short,
.button-short * span {
  line-height: 25px !important;
}

.raised-button {
  box-shadow: 0 3px 1px -2px #0003, 0 2px 2px #00000024, 0 1px 5px #0000001f !important;
}

.action-button-width {
  text-align: center;
}

.action-button-width {
  max-width: 150px !important;
  width: 150px !important;
  min-width: 150px !important;
}

/* PAGE CONTAINERS */
.page-description-container {
  width: 100%;
  box-sizing: border-box;
}

.content-container {
  margin: auto;
  display: flex;
  // min-height: 100vh;
  // background-color: #fff;
}

.content-container:not(.no-ds) {
  // box-shadow: 2px 2px 4px 4px rgba(0, 0, 0, 0.04);
}

.main-content-container {
  // padding: 20px;
  min-height: 80vh;
  // background-color: #fff;
  position: relative;
}

// .content-container,
// .center-content-container {
//   max-width: 1900px;
// }

.content-container {
  margin: auto;
  max-width: 1200px;

  &.extended {
    max-width: 1500px;

    .blog-content-container {
      max-width: calc(1500px - 6rem);
      width: 100%;
    }

    .center-content-container {
      max-width: 1300px;
    }
  }

  &.extended-reduced {
    max-width: 1300px;

    .center-content-container {
      max-width: 1000px;
    }

    .side-container {
      max-width: 260px;
      min-width: 210px;
      width: 20%;
      margin-right: 3rem;
    }
  }

  .center-content-container {
    width: 100%;
    max-width: 1000px;
    margin: 0 auto;
    min-width: 0;

    margin-right: 3rem;
  }

  .side-container {
    max-width: 255px;
    min-width: 255px;
    width: 30%;
    margin-right: 3rem;
  }
}

@media only screen and (max-width: 768px) {
  .blog-content-container {
    max-width: calc(1500px - 1rem);
    width: 100%;
  }
}

@media only screen and (max-width: 1200px) {
  .side-container {
    margin-right: 1rem !important;
  }
}

@media only screen and (max-width: 992px) {
  .content-container .center-content-container {
    margin-right: 0rem;
  }

  .side-container {
    max-width: 210px;
    width: 100%;
  }
}

.side-container.lg {
  display: block;
}

@media only screen and (max-width: 1300px) {
  .center-content-container {
    width: 100%;
    max-width: 1300px;
    margin: 0 auto;
    min-width: 0;
  }

  .content-container {
    margin: auto;
  }

  .side-container.lg {
    display: block;
  }
}

@media only screen and (max-width: 1200px) {
  .side-container.lg {
    display: none;
  }
}

@media only screen and (max-width: 992px) {
  .content-container {
    margin: auto;
  }

  .side-container {
    display: none;
  }

  .center-content-container {
    padding-right: 0 !important;
  }

  // CORRECT TO 90px
  .action-button-width.can_shrink {
    max-width: 80px !important;
    width: 80px !important;
    min-width: 80px !important;
  }

  .center-content-container {
    width: 100%;
    min-width: 0;
  }
}

/* MOBILE NAVIGATION */
@media only screen and (max-width: 1500px) {
  .large-desktop-only {
    display: block !important;
  }
}

/* MOBILE MODE */
@media only screen and (max-width: 750px) {
  .n-a-price {
    width: fit-content;
    min-width: 0px !important;
  }

  .larger.action-button-width {
    max-width: 80px !important;
    width: 80px !important;
    min-width: 80px !important;
  }

  .dynamic.action-button-width {
    max-width: 50px !important;
    width: 50px !important;
    min-width: 50px !important;
  }

  .max-content {
    width: 100px !important;
    max-width: 100px !important;
    min-width: 100px !important;

    &.mat-raised-button,
    .mat-raised-button {
      padding: 0 4px !important;
    }
  }

  .btn-container-padding {
    margin: auto;
    padding: 0 5px;
  }

  .mobile-flex {
    display: block;
  }

  .mobile-only {
    display: block !important;
  }

  .large-desktop-only {
    display: none !important;
  }

  .desktop-flex-only {
    display: none;
  }

  .desktop-only {
    display: none !important;
  }

  .page-width {
    width: 95%;
  }
}

/* ANIMATIONS */
.skeleton {
  div.plate-size {
    width: 180px;
    height: 48px;

    &.x-large {
      width: 310px;
      height: 71px;
    }
  }

  .price-container {
    p.price-size {
      max-width: 90px;
      height: 15px;
      margin: 5px auto !important;
    }
  }

  p,
  span,
  small {
    color: transparent !important;
  }

  div.action-button-size {
    height: 34px;
  }

  div.icon-button {
    width: 30px;
    height: 30px;
    border-radius: 50%;
    margin: auto;
  }

  @media only screen and (max-width: 750px) {
    div.desktop-only.skeleton-item {
      display: none;
    }
  }

  @media only screen and (max-width: 650px) {
    div.plate-size {
      width: 120px;
      height: 34px;
    }
  }

  .skeleton-item {
    overflow: hidden;
    position: relative;
    background-color: #dddbdd;
    opacity: 0.8;
    border-radius: 4px;
  }

  .skeleton-item:after {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    transform: translate(-100%);
    background-image: linear-gradient(
      90deg,
      rgba(255, 255, 255, 0) 0,
      rgba(255, 255, 255, 0.2) 20%,
      rgba(255, 255, 255, 0.5) 60%,
      rgba(255, 255, 255, 0)
    );
    animation: shimmer 1.5s infinite;
    content: "";
  }
}

@keyframes shimmer {
  100% {
    transform: translateX(100%);
  }
}

html,
body {
  height: 100%;
}

body {
  margin: 0;
}

button.mat-mini-fab.mat-button-base span.mat-button-wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
}

.swiper-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: relative;

  .swiper-nav.swiper-prev,
  .swiper-nav.swiper-next {
    position: absolute;
    z-index: 10;
    cursor: pointer;
    background: #e5e5e5;
    border-radius: 50%;
  }

  .swiper-nav.swiper-prev {
    left: 10px;
  }

  .swiper-nav.swiper-next {
    right: 10px;
  }
}

.swiper.swiper-border .swiper-wrapper .swiper-slide {
  border: 1px solid white;
  border-top: 0;
  border-bottom: 0;
}

.swiper .swiper-wrapper .swiper-slide {
  margin: auto;
}

.swiper {
  .swiper-pagination-bullet {
    background: white;
    border: 1px solid #327b8d;
  }

  .swiper-pagination-bullet.swiper-pagination-bullet-active {
    background: #327b8d;
  }
}

.thumbSwiper.swiper .swiper-wrapper .swiper-slide {
  opacity: 0.2;
  cursor: pointer;
}

.thumbSwiper.swiper .swiper-wrapper .swiper-slide img {
  transition-duration: 0.1s;
  width: 100%;
  transform: scale(0.7);
  display: block;
  margin: auto;
}

.thumbSwiper.swiper .swiper-wrapper .swiper-slide-thumb-active img {
  transition-duration: 0.2s;
  transform: scale(1);
  width: 100%;
  display: block;
  margin: auto;
}

.thumbSwiper.swiper .swiper-wrapper .swiper-slide-thumb-active {
  opacity: 1;
}

.mat-dialog-container {
  will-change: transform, opacity;
}

::ng-deep .no-margin.mat-form-field-type-mat-select {
  .mat-form-field-wrapper {
    margin-bottom: 0 !important;
    padding-bottom: 0 !important;
  }

  .mat-form-field-infix {
    padding: 0.25em 0 !important;
  }
}

::ng-deep .no-underline,
.no-underline {
  margin-bottom: -1.25em;

  .mat-form-field-underline {
    display: none;
  }
}

.mat-button,
.mat-icon-button,
.mat-stroked-button,
.mat-flat-button,
.mat-raised-button {
  line-height: 36px !important;
  padding: 0 16px !important;
  border-radius: 4px !important;
  border: none !important;
  margin: 0 !important;
}

.mat-paginator-range-actions {
  .mat-icon-button {
    width: 28px !important;
    height: 28px !important;
    line-height: 28px !important;
  }
}

.short .mat-form-field-wrapper {
  margin-bottom: 0 !important;
  padding-bottom: 0 !important;
  margin-top: 0 !important;
}

.short .mat-select-arrow {
  margin-top: 5px !important;
}

.short .mat-form-field-infix {
  padding: 0.25em 0 !important;
  border-top-width: 6px !important;
}

.mat-slide-toggle-label {
  display: flex !important;
}

.mat-form-field-outline {
  background: white !important;
}

.mat-progress-bar {
  height: 8px !important;
}

//$__mat_input_colour: #327b8d;
$__mat_input_colour: #000;

.mat-progress-bar-fill:after {
  background-color: $__mat_input_colour !important;
}

.mat-progress-bar-buffer {
  background-color: #f9f9f9 !important;
}

.mat-checkbox-checked .mat-checkbox-background {
  background-color: $__mat_input_colour !important;
}

.mrb .mat-radio-label {
  display: flex !important;
}
.mrb.mat-radio-button .mat-radio-inner-circle {
  background-color: $__mat_input_colour !important;
}
.mrb.mat-radio-button.mat-radio-checked .mat-radio-outer-circle {
  border-color: $__mat_input_colour !important;
}

.mat-radio-outer-circle,
.mat-checkbox-frame {
  border-width: 1px !important;
}
.mat-checkbox-background,
.mat-checkbox-frame {
  border-radius: 4px !important;
}

.mat-checkbox-layout,
.mat-checkbox-label {
  width: 100%;
  text-wrap: wrap;
}

// .mat-checkbox-checked .mat-checkbox-ripple .mat-ripple-element {
//   opacity: 0.03 !important;
//   background-color: #327b8d !important;
//   background: #327b8d !important;
// }
.mat-ripple-element.mat-checkbox-persistent-ripple,
.mat-ripple-element {
  background: $__mat_input_colour !important;
}

.mat-ripple {
  .mat-ripple-element.mat-radio-persistent-ripple {
    background-color: $__mat_input_colour;
  }
}

.mat-progress-spinner circle,
.mat-spinner circle {
  stroke: #214953 !important;
}

.bootstrap label {
  display: flex;
}

::ng-deep,
*,
body.bootstrap,
html {
  .mat-progress-spinner circle,
  .mat-spinner circle {
    stroke: #214953 !important;
  }

  .mat-progress-bar {
    height: 8px !important;
  }

  .mat-progress-bar-fill:after {
    background-color: #327b8d !important;
  }

  .mat-progress-bar-buffer {
    background-color: #f9f9f9 !important;
  }

  .mat-form-field-outline {
    background: white !important;
  }

  .mat-checkbox-layout,
  .mat-slide-toggle-label {
    display: flex !important;
  }

  .no-margin.mat-form-field .mat-form-field-wrapper {
    margin-bottom: 0 !important;
    padding-bottom: 0 !important;
  }

  .mat-checkbox-background,
  .mat-checkbox-checked.mat-primary .mat-checkbox-background,
  mat-option .mat-primary .mat-pseudo-checkbox-checked,
  .mat-checkbox.mat-checkbox-checked
    .mat-checkbox-inner-container
    .mat-checkbox-background {
    background-color: #327b8d !important;
  }

  .mrb .mat-radio-button .mat-radio-inner-circle,
  mat-radio-group
    mat-radio-button
    .mat-radio-button.mat-primary
    .mat-radio-inner-circle {
    background-color: #327b8d !important;
  }
  .mrb .mat-radio-button.mat-radio-checked .mat-radio-outer-circle,
  mat-radio-group
    mat-radio-button
    .mat-radio-button.mat-primary.mat-radio-checked
    .mat-radio-outer-circle {
    border-color: #327b8d !important;
  }

  .mat-primary .mat-option.mat-selected:not(.mat-option-disabled) {
    color: #327b8d !important;
  }

  .mat-checkbox-checked .mat-checkbox-ripple .mat-ripple-element {
    opacity: 0.03 !important;
    background-color: #327b8d !important;
    background: #327b8d !important;
  }

  .mat-checkbox .mat-ripple-element.mat-checkbox-persistent-ripple,
  .mat-checkbox .mat-ripple-element {
    background: #327b8d !important;
  }

  .mat-checkbox-checked .mat-checkbox-background,
  .mat-checkbox-indeterminate .mat-checkbox-background {
    background-color: #327b8d !important;
    background: #327b8d !important;
  }

  .mat-step-header .mat-step-icon-selected,
  .mat-step-header .mat-step-icon-state-done,
  .mat-step-header .mat-step-icon-state-edit {
    background-color: #327b8d !important;
    background: #327b8d !important;
    color: white !important;
  }

  .mat-radio-button {
    display: block !important;

    .mat-radio-outer-circle {
      border-color: #327b8d !important;
    }

    .mat-radio-inner-circle {
      background-color: #327b8d !important;
    }

    .mat-ripple {
      .mat-ripple-element.mat-radio-persistent-ripple {
        background-color: #327b8d;
      }
    }
  }

  .mat-step-header .mat-step-icon-selected,
  .mat-step-header .mat-step-icon-state-done,
  .mat-step-header .mat-step-icon-state-edit {
    background-color: #327b8d !important;
    color: white !important;
  }
}

@media (hover: none) {
  .mat-ripple,
  .mat-button-focus-overlay,
  .mat-ripple.mat-button-ripple {
    display: none !important;
  }

  mat-tooltip-component {
    display: none !important;
  }
}

// SEARCH INPUTS
.search-input-sizing {
  width: 370px !important;
  height: 75px !important;
  max-width: 370px !important;
  min-width: 0px !important;

  &.centered {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
  }
}

.hasSearchNav {
  .banner-content-container {
    // padding-top: 40px;
  }
}

.bootstrap .border-super-light {
  border-color: #f4f7f5 !important;
}

.page-title {
  background: rgb(0, 0, 0);
  background: linear-gradient(
    168deg,
    rgba(0, 0, 0, 1) 0%,
    rgba(255, 255, 255, 0) 65%
  );
  padding: 20px;
  width: 100%;

  &.right {
    text-align: right !important;

    h1:after {
      left: auto;
      right: 0;
    }
  }

  h1 {
    color: white;
    font-size: 48px;
    line-height: 50px;
    margin: 0;
    font-family: "Arimo", sans-serif !important;
    margin-bottom: 10px;
    position: relative;
    padding-bottom: 7px; // 5px + the 2px height
    display: inline-block;
  }

  h1::after {
    content: "";
    position: absolute;
    width: calc(100% + 10px);
    max-width: 450px;
    height: 2px;
    background-color: white;
    bottom: 0;
    left: 0;
  }

  .sub {
    padding-top: 0px;
    font-size: 22px;
    color: white;
    display: block;
  }
}

@media only screen and (max-width: 750px) {
  .page-title {
    background: rgb(0, 0, 0);
    background: linear-gradient(
      to bottom,
      rgba(0, 0, 0, 1) 0%,
      rgba(255, 255, 255, 0) 100%
    );
    padding: 10px 12px;

    h1 {
      font-size: 18px;
      line-height: 18px;
      margin-bottom: 0;
      display: inline-block;
    }

    h1::after {
      max-width: 250px;
      width: calc(100% + 10px);
    }

    span.sub {
      display: block;
      font-size: 14px;
      font-weight: 400;
    }
  }
}

.banner-content-container {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 100%;
  height: 100%;
  flex: 1 1 auto;
  // padding-bottom: 40px;

  .banner-title {
    position: relative;
    height: 100%;
    width: 100%;
    flex: 1 1 auto;

    .page-banner-title {
      position: absolute;
      z-index: 10;
      padding: 10px;
      text-align: center;
      align-self: center;

      h1 {
        text-transform: capitalize;
        color: #fff;
        // font-family: "Baskervville", serif !important;
        font-size: 40px;
        line-height: 100%;
        font-weight: 700;
        padding: 10px;
        margin: 0;
        font-style: italic;
      }

      top: 0;
      left: 0;

      &.top {
        bottom: auto;
        top: 0;
      }

      &.bottom {
        top: auto;
        bottom: 0;
      }

      &.right {
        left: auto;
        right: 0;
      }

      &.middle {
        left: 0;
        right: 0;
      }

      &.left {
        right: auto;
        left: 0;
      }
    }
  }
}

@media only screen and (max-width: 950px) {
  .banner-content-container {
    .banner-title {
      .page-banner-title {
        h1 {
          font-size: 30px;
        }
      }
    }
  }
}

@media only screen and (max-width: 650px) {
  .banner-content-container {
    .banner-title {
      .page-banner-title {
        h1 {
          padding: 5px;
        }
      }
    }
  }
}

@media only screen and (max-width: 550px) {
  .banner-content-container {
    .banner-title {
      .page-banner-title {
        h1 {
          font-size: 20px;
        }
      }
    }
  }
}

@media only screen and (max-width: 650px) {
  .hasSearchNav {
    .banner-content-container {
      padding-top: 0px;
    }
  }

  .banner-content-container {
    .banner-title {
      .page-banner-title {
        font-size: 22px;
      }
    }
  }
}

@media only screen and (max-width: 750px) {
  .banner-content-container {
    padding-bottom: 15px;

    .banner-title {
      .noMobile {
        display: none;
      }
    }
  }
}

.search-area {
  .header {
    text-align: center;
    color: black;
    font-size: 16px;
    margin: 5px auto 0;
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: 30px;
    font-weight: 400 !important;
  }
}

.search-area.inner {
  width: 100%;
  height: 100%;
  padding: 10px 0px;

  display: flex;
  flex-direction: row;
  align-items: flex-end;
  justify-content: center;

  .box-container {
    // background-color: rgba(0, 0, 0, 0.5);
    padding: 10px 0px;
    border-radius: 4px;

    .sub-message {
      display: block;
      text-align: center;
      color: white;
      margin-bottom: 5px;
    }
  }

  .tabbed-button-section {
    width: 100%;
    height: auto !important;
    margin: auto;

    &.multi {
      display: flex;
      justify-content: space-between;
      align-items: center;
    }

    &.secondary {
      margin-top: 5px;
    }

    button {
      color: black;
      background: white;

      padding: 3px;
      font-size: 14px;
      cursor: pointer;
      border-radius: 4px;
      flex: 1 1 0;

      display: flex;
      align-items: center;
      justify-content: center;
      opacity: 0.5;

      &:hover:not(.active) {
        opacity: 1;
      }

      &.active {
        opacity: 1;
        color: black !important;
        background: #fff !important;
      }
    }
  }
}

@media only screen and (max-width: 750px) {
  .search-area {
    .box-container {
      padding: 10px;
      // background-color: transparent;

      .sub-message {
        display: none;
      }
    }
  }
}

@media only screen and (max-width: 600px) {
  .search-input-sizing {
    width: 325px !important;
    height: 65px !important;
    max-width: 325px !important;
    min-width: 0px !important;
  }
}

.search-input-container {
  transform: translateY(-35%);
  margin-bottom: -60px;
}

.search-input-space-top {
  margin-bottom: -85px;
}

@media only screen and (max-width: 750px) {
  .search-input-container {
    transform: translateY(-25%);
    margin-bottom: -50px;
  }

  .search-input-space-top {
    margin-bottom: -50px;
  }

  .banner-content-container {
    .banner-title {
      .page-banner-title {
        h1 {
          display: none !important;
        }
      }
    }
  }
}

.card-logos {
  display: flex;
  justify-content: center;
  align-items: center;

  .logo {
    height: 22px;
    margin: 5px;
  }
}

.banner-content-container {
  .banner-title {
    .page-banner-title {
      h1 {
        display: none !important;
      }
    }
  }
}

.accordion-button {
  outline: none !important;

  &:not(.collapsed) {
    box-shadow: inset 0 -1px #00000020;
  }

  &.collapsed {
    box-shadow: none;
  }

  &:focus {
    border-color: rgba(0, 0, 0, 0.125);
  }
}

.accordion-button:not(.collapsed) {
  background-color: #fff;
  color: #212529;
}

.w-content {
  width: fit-content;
}

.w-10 {
  width: 20%;
}

.w-20 {
  width: 20%;
}

.w-33 {
  width: 33%;
}

.w-60 {
  width: 60%;
}

.w-80 {
  width: 80;
}

.h-0 {
  height: 0 !important;
}

.bg-platex {
  background-color: #0e2b33;
}

.bg-platex-light {
  background-color: #327b8d;
}

.text-platex-light {
  color: #327b8d;
}

.mat-form-field-appearance-outline.mat-focused .mat-form-field-outline-thick,
.mat-form-field.mat-focused .mat-form-field-label {
  color: #327b8d !important;
}

/* new */
.mat-button-disabled {
  opacity: 0.25;
}

.banner-tint,
.banner-tint .compare-banner-height {
  background-color: rgba(13, 13, 13, 0.22);
}

.new-banner-height {
  min-height: 300px;
}

.compare-banner-height {
  min-height: 300px;
}

.landing-banner-height {
  min-height: 250px;
  height: 85svh;
  max-height: 350px;
}

@media only screen and (max-width: 1390px) {
  .landing-banner-height {
    min-height: 250px;
    height: 85svh;
    max-height: 300px;
  }
}

@media only screen and (max-width: 768px) {
  .new-banner-height {
    min-height: 150px;
  }

  .mobile-banner-height {
    min-height: 444px;
  }

  .landing-banner-height {
    height: 55svh;
    min-height: 55svh;
    max-height: 55svh;
  }

  .compare-banner-height {
    height: 75svh;
    min-height: 35svh;
    max-height: 40svh;
  }
}

.compare-mobile-background {
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
}

.desktop-mobile-background {
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
}

.desktop-mobile-background-left {
  background-size: cover;
  background-position: left;
  background-repeat: no-repeat;
}

.desktop-mobile-background-right {
  background-size: cover;
  background-position: right;
  background-repeat: no-repeat;
}

.desktop-mobile-bottom {
  background-size: cover;
  background-position: center bottom;
  background-repeat: no-repeat;
}

@media only screen and (max-width: 768px) {
  .compare-mobile-background {
    background-size: cover;
    background-position: right;
    background-repeat: no-repeat;
  }
}

.ltr_gradient {
  background: linear-gradient(
    90deg,
    rgba(255, 255, 255, 0) 0%,
    rgba(0, 0, 0, 0.5) 30%
  );
}

.gradient-fade {
  background: rgb(243, 243, 243);
  background: linear-gradient(
    180deg,
    rgba(255, 255, 255, 0) 0%,
    rgba(243, 243, 243, 1) 76%
  );
}

.search_all_link {
  display: block;
  text-align: center;
  text-decoration: underline;
  color: #327b8d;
  margin-top: 5px;
  font-size: 15px;

  &:hover {
    font-style: italic;
  }
}

.bootstrap .overflow-unset {
  overflow: unset !important;
}

.bootstrap p a {
  color: #327b8d !important;
}

.bootstrap .accordion-button:not(.collapsed) {
  background-color: transparent;
}

.bootstrap .accordion-button {
  color: #327b8d !important;
}

.bootstrap a:hover .link-underline {
  text-decoration: underline !important;
}

.bootstrap #tp-seller-accordion {
  .accordion-button:focus {
    border-color: transparent;
    -webkit-box-shadow: none;
    -moz-box-shadow: none;
    box-shadow: none;
  }

  .accordion-button::after {
    display: none;
  }

  .accordion-button::before {
    flex-shrink: 0;
    width: 1.25rem;
    height: 1.25rem;
    margin-left: 0;
    margin-right: auto;
    content: "";
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23212529'%3e%3cpath fill-rule='evenodd' d='M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z'/%3e%3c/svg%3e");
    background-repeat: no-repeat;
    background-size: 1.25rem;
    transition: transform 0.2s ease-in-out;
  }

  .accordion-button:not(.collapsed)::before {
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%230c63e4'%3e%3cpath fill-rule='evenodd' d='M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z'/%3e%3c/svg%3e");
    transform: rotate(-180deg);
  }

  .accordion-button.collapsed {
    // border-bottom-right-radius: 20px;
    // border-bottom-left-radius: 20px;
  }
}

.bootstrap {
  .gap-x-1 {
    gap: 0 0.25rem !important;
  }

  .gap-x-2 {
    gap: 0 0.5rem !important;
  }

  .gap-x-3 {
    gap: 0 1rem !important;
  }

  .gap-x-4 {
    gap: 0 1.5rem !important;
  }

  .gap-x-4 {
    gap: 0 3rem !important;
  }
}

.bootstrap .opacity-100 {
  opacity: 1;
}

.bootstrap .opacity-75 {
  opacity: 0.75;
}

.bootstrap .opacity-50 {
  opacity: 0.5;
}

.bootstrap .opacity-25 {
  opacity: 0.25;
}

.bootstrap .h-mb {
  margin-bottom: 28px !important;
}

.bootstrap .hs-mb {
  margin-bottom: 18px !important;
}

.hs-spacing {
  height: 36px;
}

.s-spacing {
  height: 50px;
}

@media screen and (max-width: 768px) {
  .s-spacing {
    height: 40px;
  }
}

@media only screen and (max-width: 900px) {
  .desktop-mobile-background {
    background-size: cover;
  }

  body.bootstrap .w-md-100 {
    width: 100% !important;
  }

  .md-no-gradient {
    background: rgba(0, 0, 0, 0.5);
  }
}

/*plus*/
.bootstrap .accordion-button.collapsed::after {
  background-image: url("data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiA/PjwhRE9DVFlQRSBzdmcgIFBVQkxJQyAnLS8vVzNDLy9EVEQgU1ZHIDEuMS8vRU4nICAnaHR0cDovL3d3dy53My5vcmcvR3JhcGhpY3MvU1ZHLzEuMS9EVEQvc3ZnMTEuZHRkJz48c3ZnIGVuYWJsZS1iYWNrZ3JvdW5kPSJuZXcgMCAwIDUwIDUwIiBoZWlnaHQ9IjUwcHgiIGlkPSJMYXllcl8xIiB2ZXJzaW9uPSIxLjEiIHZpZXdCb3g9IjAgMCA1MCA1MCIgd2lkdGg9IjUwcHgiIHhtbDpzcGFjZT0icHJlc2VydmUiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgeG1sbnM6eGxpbms9Imh0dHA6Ly93d3cudzMub3JnLzE5OTkveGxpbmsiPjxyZWN0IGZpbGw9Im5vbmUiIGhlaWdodD0iNTAiIHdpZHRoPSI1MCIvPjxsaW5lIGZpbGw9Im5vbmUiIHN0cm9rZT0iIzM3OTFBOCIgc3Ryb2tlLW1pdGVybGltaXQ9IjEwIiBzdHJva2Utd2lkdGg9IjQiIHgxPSI5IiB4Mj0iNDEiIHkxPSIyNSIgeTI9IjI1Ii8+PGxpbmUgZmlsbD0ibm9uZSIgc3Ryb2tlPSIjMzc5MUE4IiBzdHJva2UtbWl0ZXJsaW1pdD0iMTAiIHN0cm9rZS13aWR0aD0iNCIgeDE9IjI1IiB4Mj0iMjUiIHkxPSI5IiB5Mj0iNDEiLz48L3N2Zz4=") !important;
}

/*minus*/
.bootstrap .accordion-button:not(.collapsed)::after {
  background-image: url("data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiA/PjwhRE9DVFlQRSBzdmcgIFBVQkxJQyAnLS8vVzNDLy9EVEQgU1ZHIDEuMS8vRU4nICAnaHR0cDovL3d3dy53My5vcmcvR3JhcGhpY3MvU1ZHLzEuMS9EVEQvc3ZnMTEuZHRkJz48c3ZnIGVuYWJsZS1iYWNrZ3JvdW5kPSJuZXcgMCAwIDUwIDUwIiBoZWlnaHQ9IjUwcHgiIGlkPSJMYXllcl8xIiB2ZXJzaW9uPSIxLjEiIHZpZXdCb3g9IjAgMCA1MCA1MCIgd2lkdGg9IjUwcHgiIHhtbDpzcGFjZT0icHJlc2VydmUiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgeG1sbnM6eGxpbms9Imh0dHA6Ly93d3cudzMub3JnLzE5OTkveGxpbmsiPjxyZWN0IGZpbGw9Im5vbmUiIGhlaWdodD0iNTAiIHdpZHRoPSI1MCIvPjxsaW5lIGZpbGw9Im5vbmUiIHN0cm9rZT0iIzM3OTFBOCIgc3Ryb2tlLW1pdGVybGltaXQ9IjEwIiBzdHJva2Utd2lkdGg9IjQiIHgxPSI5IiB4Mj0iNDEiIHkxPSIyNSIgeTI9IjI1Ii8+PC9zdmc+") !important;
}

.bootstrap .bg-overlay {
  background-color: rgba(0, 0, 0, 0.75);
}

.above-shadow-sm {
  box-shadow: -0 -0.125rem 0.25rem rgba(0, 0, 0, 0.075) !important;
}

.inset-shadow-sm {
  box-shadow: inset 0 0.125rem 0.25rem rgba(0, 0, 0, 0.075) !important;
}

.bootstrap .fs-xs {
  font-size: 11px !important;
  line-height: 14px !important;
}

.bootstrap .translate-bottom {
  transform: translateY(100%);
}

@media only screen and (max-width: 768px) {
  .bootstrap .no-border {
    border: none !important;
  }
}

.rounded-lg {
  border-radius: 12px;
}

.rounded-top-3 {
  border-radius: 0.3rem 0.3rem 0 0 !important;
}

.rounded-bottom-3 {
  border-radius: 0 0 0.3rem 0.3rem !important;
}

.bootstrap .border-bottom-transparent {
  border-bottom: 1px solid transparent !important;
}

.bootstrap .border-transparent {
  border: 1px solid transparent !important;
}

.bootstrap .d-tiny-block {
  display: none !important;
}
.bootstrap .d-tiny-none {
  display: block !important;
}
@media only screen and (min-width: 390px) {
  .bootstrap .d-tiny-block {
    display: block !important;
  }
  .bootstrap .d-tiny-none {
    display: none !important;
  }
}

// FONTS
.bootstrap {
  .fs-md-45 {
    font-size: 45px !important;
  }
  .fs-md-35 {
    font-size: 35px !important;
  }

  .fs-md-34 {
    font-size: 34px !important;
  }

  .fs-md-33 {
    font-size: 33px !important;
  }

  .fs-md-32 {
    font-size: 32px !important;
  }

  .fs-md-31 {
    font-size: 31px !important;
  }

  .fs-md-30 {
    font-size: 30px !important;
  }

  .fs-md-29 {
    font-size: 29px !important;
  }

  .fs-md-28 {
    font-size: 28px !important;
  }

  .fs-md-27 {
    font-size: 27px !important;
  }

  .fs-md-26 {
    font-size: 26px !important;
  }

  .fs-md-25 {
    font-size: 25px !important;
  }

  .fs-md-24 {
    font-size: 24px !important;
  }

  .fs-md-23 {
    font-size: 23px !important;
  }

  .fs-md-22 {
    font-size: 22px !important;
  }

  .fs-md-21 {
    font-size: 21px !important;
  }

  .fs-md-20 {
    font-size: 20px !important;
  }

  .fs-md-19 {
    font-size: 19px !important;
  }

  .fs-md-18 {
    font-size: 18px !important;
  }

  .fs-md-17 {
    font-size: 17px !important;
  }

  .fs-md-16 {
    font-size: 16px !important;
  }

  .fs-md-15 {
    font-size: 15px !important;
  }

  .fs-md-14 {
    font-size: 14px !important;
  }

  .fs-md-13 {
    font-size: 13px !important;
  }

  .fs-md-12 {
    font-size: 12px !important;
  }

  .fs-md-11 {
    font-size: 11px !important;
  }

  .fs-md-10 {
    font-size: 10px !important;
  }

  .fs-md-9 {
    font-size: 9px !important;
  }

  .fs-md-8 {
    font-size: 8px !important;
  }

  .fs-md-7 {
    font-size: 7px !important;
  }

  .fs-md-6 {
    font-size: 6px !important;
  }

  .fs-md-5 {
    font-size: 5px !important;
  }

  .fs-md-4 {
    font-size: 4px !important;
  }

  .fs-md-3 {
    font-size: 3px !important;
  }

  .fs-md-2 {
    font-size: 2px !important;
  }

  .fs-md-1 {
    font-size: 1px !important;
  }

  @media only screen and (max-width: 768px) {
    .fs-md-45 {
      font-size: 45px !important;
    }
    .fs-mb-35 {
      font-size: 35px !important;
    }

    .fs-mb-34 {
      font-size: 34px !important;
    }

    .fs-mb-33 {
      font-size: 33px !important;
    }

    .fs-mb-32 {
      font-size: 32px !important;
    }

    .fs-mb-31 {
      font-size: 31px !important;
    }

    .fs-mb-30 {
      font-size: 30px !important;
    }

    .fs-mb-29 {
      font-size: 29px !important;
    }

    .fs-mb-28 {
      font-size: 28px !important;
    }

    .fs-mb-27 {
      font-size: 27px !important;
    }

    .fs-mb-26 {
      font-size: 26px !important;
    }

    .fs-mb-25 {
      font-size: 25px !important;
    }

    .fs-mb-24 {
      font-size: 24px !important;
    }

    .fs-mb-23 {
      font-size: 23px !important;
    }

    .fs-mb-22 {
      font-size: 22px !important;
    }

    .fs-mb-21 {
      font-size: 21px !important;
    }

    .fs-mb-20 {
      font-size: 20px !important;
    }

    .fs-mb-19 {
      font-size: 19px !important;
    }

    .fs-mb-18 {
      font-size: 18px !important;
    }

    .fs-mb-17 {
      font-size: 17px !important;
    }

    .fs-mb-16 {
      font-size: 16px !important;
    }

    .fs-mb-15 {
      font-size: 15px !important;
    }

    .fs-mb-14 {
      font-size: 14px !important;
    }

    .fs-mb-13 {
      font-size: 13px !important;
    }

    .fs-mb-12 {
      font-size: 12px !important;
    }

    .fs-mb-11 {
      font-size: 11px !important;
    }

    .fs-mb-10 {
      font-size: 10px !important;
    }

    .fs-mb-9 {
      font-size: 9px !important;
    }

    .fs-mb-8 {
      font-size: 8px !important;
    }

    .fs-mb-7 {
      font-size: 7px !important;
    }

    .fs-mb-6 {
      font-size: 6px !important;
    }

    .fs-mb-5 {
      font-size: 5px !important;
    }

    .fs-mb-4 {
      font-size: 4px !important;
    }

    .fs-mb-3 {
      font-size: 3px !important;
    }

    .fs-mb-2 {
      font-size: 2px !important;
    }

    .fs-mb-1 {
      font-size: 1px !important;
    }
  }
}

.favourite-icn {
  --favourite-colour: #2f2f2f;
  --favourite-text-colour: #c70000;

  .icon {
    color: var(--favourite-colour);
  }
  &.set {
    .icon {
      color: var(--favourite-text-colour);
    }
  }
}

.notify-icn {
  --notify-colour: #2f2f2f;
  --notify-text-colour: #007850;

  .icon {
    color: var(--notify-colour);
  }
  &.set {
    .icon {
      color: var(--notify-text-colour);
    }
  }
}

.favourite-btn {
  --favourite-colour: #c70000;
  --favourite-text-colour: #ffffff;

  border: 1px solid;
  border-color: var(--favourite-colour);
  span {
    color: var(--favourite-colour);
  }
  .icon {
    color: var(--favourite-colour);
  }
  &.set {
    background-color: var(--favourite-colour);
    border-color: var(--favourite-colour);
    color: var(--favourite-text-colour);

    span {
      color: var(--favourite-text-colour);
    }

    .icon {
      color: var(--favourite-text-colour);
    }
  }
}

.notify-btn {
  --notify-colour: #007850;
  --notify-text-colour: #ffffff;

  border: 1px solid;
  border-color: var(--notify-colour);
  span {
    color: var(--notify-colour);
  }
  .icon {
    color: var(--notify-colour);
  }
  &.set {
    background-color: var(--notify-colour);
    border-color: var(--notify-colour);
    color: var(--notify-text-colour);

    span {
      color: var(--notify-text-colour);
    }

    .icon {
      color: var(--notify-text-colour);
    }
  }
}

.svg-icn {
  path,
  svg {
    width: 100%;
  }

  path.tofill {
    fill: #800020 !important;
  }
}

.sp.swiper {
  .swiper-slide {
    display: flex;
    align-items: flex-end;
  }
}

.down-arrow::after {
  content: url("data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiA/PjxzdmcgY2xhc3M9ImZlYXRoZXIgZmVhdGhlci1jaGV2cm9uLWRvd24iIGZpbGw9Im5vbmUiIGhlaWdodD0iMjQiIHN0cm9rZT0iI2ZmZiIgc3Ryb2tlLWxpbmVjYXA9InJvdW5kIiBzdHJva2UtbGluZWpvaW49InJvdW5kIiBzdHJva2Utd2lkdGg9IjIiIHZpZXdCb3g9IjAgMCAyNCAyNCIgd2lkdGg9IjI0IiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciPjxwb2x5bGluZSBwb2ludHM9IjYgOSAxMiAxNSAxOCA5Ii8+PC9zdmc+");
  width: 24px;
  height: 24px;
}
.up-arrow::after {
  content: url("data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiA/PjxzdmcgY2xhc3M9ImZlYXRoZXIgZmVhdGhlci1jaGV2cm9uLXVwIiBmaWxsPSJub25lIiBoZWlnaHQ9IjI0IiBzdHJva2U9IiNmZmYiIHN0cm9rZS1saW5lY2FwPSJyb3VuZCIgc3Ryb2tlLWxpbmVqb2luPSJyb3VuZCIgc3Ryb2tlLXdpZHRoPSIyIiB2aWV3Qm94PSIwIDAgMjQgMjQiIHdpZHRoPSIyNCIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj48cG9seWxpbmUgcG9pbnRzPSIxOCAxNSAxMiA5IDYgMTUiLz48L3N2Zz4=");
  width: 24px;
  height: 24px;
}
.down-arrow.black::after {
  content: url("data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiA/PjxzdmcgY2xhc3M9ImZlYXRoZXIgZmVhdGhlci1jaGV2cm9uLWRvd24iIGZpbGw9Im5vbmUiIGhlaWdodD0iMjQiIHN0cm9rZT0iIzAwMCIgc3Ryb2tlLWxpbmVjYXA9InJvdW5kIiBzdHJva2UtbGluZWpvaW49InJvdW5kIiBzdHJva2Utd2lkdGg9IjIiIHZpZXdCb3g9IjAgMCAyNCAyNCIgd2lkdGg9IjI0IiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciPjxwb2x5bGluZSBwb2ludHM9IjYgOSAxMiAxNSAxOCA5Ii8+PC9zdmc+");
  width: 24px;
  height: 24px;
}
.up-arrow.black::after {
  content: url("data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiA/PjxzdmcgY2xhc3M9ImZlYXRoZXIgZmVhdGhlci1jaGV2cm9uLXVwIiBmaWxsPSJub25lIiBoZWlnaHQ9IjI0IiBzdHJva2U9IiMwMDAiIHN0cm9rZS1saW5lY2FwPSJyb3VuZCIgc3Ryb2tlLWxpbmVqb2luPSJyb3VuZCIgc3Ryb2tlLXdpZHRoPSIyIiB2aWV3Qm94PSIwIDAgMjQgMjQiIHdpZHRoPSIyNCIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj48cG9seWxpbmUgcG9pbnRzPSIxOCAxNSAxMiA5IDYgMTUiLz48L3N2Zz4=");
  width: 24px;
  height: 24px;
}

.plate-code-input input {
  font-family: UKReg !important;
  font-size: 20px !important;
  padding: 0;
  height: 45px !important;
  min-width: auto !important;
  box-shadow: inset 4px 4px 4px rgb(0, 0, 0, 0.08);
  background-color: #f9e57d !important;
}

.plate-code-input input:not(.has-value):not(:focus) {
  box-shadow: inset 4px 4px 4px rgb(0, 0, 0, 0.08);
}

.plate-code-input code-input {
  justify-content: center;
}

.plate-code-input span {
  display: block;
  flex: 1;
  max-width: 51px;
  width: 100%;
  padding: 0 5px !important;
}

// .dd-raw pla-custom-dropdown {
//   position: absolute;
// }

#tsparticles canvas {
  position: relative !important;
  width: 100%;
  height: 100%;
}
